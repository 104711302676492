.flex{
    display: flex;
    gap: 1.2rem;
    align-items: center;
}
.path{
    font-weight: 600;
    font-size: 1.2rem;
}
.dark{
    color: var(--black);
    text-decoration: none;
}
.light{
    color: var(--accent-dark-gray);
    text-decoration: none;
    cursor: pointer;
}
.link:hover{
    color: var(--black);
}