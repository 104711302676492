.tss-tlx3x1-MUIDataTableToolbar-root {
  display: none !important;
}
.MuiTableCell-root {
  padding: 0.6rem !important;
}
.MuiTableCell-head button {
  padding: 0 !important;
}
.MuiTableCell-head {
  background: #f1f1f1 !important;
  font-size: 1.4rem !important;
}
.MuiTableCell-root button:hover {
  background: none !important;
}
.MuiTableCell-body {
  font-size: 1.3rem !important;
}
