.layout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  overflow-x: hidden;
}

.main-box {
  /* margin-left: 24rem; */
  width: 100%;
  position: relative;
}
.main-exp{
  margin-left: 24rem;
}
.main-coll{
  margin-left: 6rem;
}

@media (min-width: 1051px){
  .main-exp{
   margin-left: 24rem;
  }
  .main-coll{
    margin-left: 6rem;
  }
}
@media (max-width: 1050px){
  .main-exp{
   margin-left: 19rem;
  }
}
@media (max-width: 900px){
  .main-box{
   margin-left: 0;
  }
}
