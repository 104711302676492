/* .MuiCardContent-root{
    display: flex;
    min-height: 8rem;
    flex-direction: column;
    padding: 0 !important;
    gap: 2rem;
} */
.card-content-heading{
    font-size: 1.6rem;
    margin-bottom: 2.5rem;
}
.card-content-content section{
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}