:root {
  --primary: #fcfcfc;
  --secondary: #cb272f;
  --cta: #6eb066;
  --sidebar-bg: #f8f3f3;
  --gray-bg: #f2f2f2;
  --accent-dark-gray: #828282;
  --accent-light-gray: #bdbdbd;
  --black: #000000;
  --white: #ffffff;
  --background: #e5e5e5;
  --header-background: #fff9f9;
}
