.header {
  width: 100%;
  max-height: 4.7rem;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 8rem 1rem 6rem;
  position: fixed;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  top: 0;
  z-index: 200;
}
.headerDrawer {
  height: 4.7rem !important;
  background: var(--white) !important;
}
.header-toolbar, .css-hyum1k-MuiToolbar-root {
  height: 4.7rem !important;
}

@media (min-width: 600px){
  .header-toolbar, .css-hyum1k-MuiToolbar-root {
    min-height: 4.7rem !important;
  }
}


