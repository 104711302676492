@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './constants.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background);
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 0rem;
  height: 0rem;
  background-color: white;
  cursor: pointer;
}
