.dropdown > input{
    padding: 0 !important;
}
.add-form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.sp-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.err-form{
    margin-top: .7rem;
    color: red;
    width: max-content;
}
.form-button{
    height: 3rem;
  padding: 0.1rem 1rem;
  border: none;
  border-radius: 0.3rem;
  font-weight: 500;
  cursor: pointer;
  min-width: 11rem;
  background: var(--cta);
  color: var(--white);
}
.datepicker-cal,
.react-datepicker,
.react-datepicker__month-container{
    width: 100%;
    font-size: 1.1rem;
}
.datepicker-cal, .react-datepicker__header{
    background-color: transparent !important;
}
.react-datepicker__day--in-range, .react-datepicker__day--selected{
    background: var(--secondary) !important;
}
.react-datepicker .react-datepicker__current-month{
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: .5rem;
}