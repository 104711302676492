.pro-button {
  margin: 1rem 0.25rem 1rem 0.6rem !important;
  padding: 0.6rem 2rem !important;
  min-width: 10rem !important;
  font-size: 1.2rem !important;
}
/* .pro-button:hover {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.545) , transparent) !important;
} */

.pro-button-mx-0 {
  margin: 1rem 0 0 0 !important;
}
