.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: var(--header-background);
  border-bottom: 2rem solid var(--secondary);
}

.auth .auth-form {
  width: 36rem !important;
  margin: 1rem;
}

.auth .input-label {
  font-size: 1.3rem;
  font-weight: 500;
}

.auth .footer-text {
  font-size: 1.3rem;
}

.auth .footer-text a {
  text-decoration: none;
  font-weight: 600;
}
