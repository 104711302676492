.sidebar {
  height: 100% !important;
  background: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0;
  gap: 0.5rem;
  margin-top: 4.7rem;
  padding-top: 2rem;
  position: fixed;
  left: 0;
  overflow-x: hidden;
}
.side-exp{
  width: 24rem !important;
}
.side-coll{
  width: 6rem !important;
  padding-left: 0 !important;
}
.nav-link {
  padding: 1rem;
  width: 100%;
  text-decoration: none;
  color: var(--black);
  border-radius: 0.25rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.09rem;
  display: flex;
  align-items: center;
  height: 5.6rem;
  gap: 1.5rem;
}
.nav-link:hover {
  color: var(--white) !important;
  background: var(--secondary);
}
.nav-coll{
  justify-content: center;
}
.sidebarDrawer {
  background: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 2rem;
}

@media (max-width: 900px){
 .sidebar{
  display: none;
 }
}