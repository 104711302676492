.graphs{
    display: flex;
    gap: 1.2rem;
}
.admin{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 3rem;
    /* margin-left: 20rem; */
}
.sec-title{
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.weekly-cost{
    font-size: 2rem;
    padding: .8rem 1.6rem;
    background: var(--gray-bg);
}
