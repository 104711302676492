.banner {
  height: 3.6rem;
  padding: 0.9rem;
  text-align: center;
  font-size: 1.4rem;
  position: absolute;
  width: 100%;
  top: 4.75rem;
  left: 0;
}
.banner-error {
  background: #eb5757;
  color: var(--white);
}
.banner-success {
  background: #faf4d5;
  color: var(--black);
}
