/* Button Styles */
.view-all,
.sec-outlined,
.button-outline,
.contained {
  height: 3rem;
  padding: 0.1rem 1rem;
  border: none;
  border-radius: 0.3rem;
  font-weight: 500;
  cursor: pointer;
  margin-left: 0.5rem;
  min-width: 11rem;
}
.sec-outlined {
  background: var(--white);
  color: var(--secondary);
  border: 0.1rem solid var(--accent-light-gray);
}
.view-all {
  background: var(--cta);
  color: var(--white);
}
.contained {
  background: var(--secondary);
  color: var(--white);
}
.button-outline {
  background: var(--white);
  color: var(--secondary);
  border: 0.1rem solid var(--secondary);
}

/* Overriding MUI CSS */
.MuiFormControl-root {
  margin-top: 0.5rem !important;
}
.MuiFormControl-root input {
  padding: 1.2rem !important;
  font-size: 1.2rem;
}
.css-1tv8u19-container {
  width: 100% !important;
}

/* Pagination */
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  font-size: 1.1rem !important;
}
.MuiTablePagination-selectLabel {
  font-size: 1.1rem !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  font-size: 1.1rem !important;
}

/* Toast */
.Toastify__toast-container{
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: .05rem;
}

.mobile-content {
  display: none;
}

@media (max-width: 768px) {
  .mobile-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 1rem;
  }
}
