.page-container {
  width: 100%;
  min-height: 100vh;
  background: #fffcfc;
  padding: 5rem 9rem 5rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3rem;
}
.page-container-timesheet {
  width: 100%;
  min-height: 100vh;
  background: #fffcfc;
  padding: 0 9rem 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* margin-top: 3rem; */
}
.admin{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3rem;
  /* margin-left: 20rem; */
}
.section-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.section-top h1 {
  font-size: 3.6rem;
}
.section-info {
  background: var(--gray-bg);
  padding: 0.8rem 1.6rem;
  font-size: 1.3rem;
}
.section-three {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.section-filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
}
.filter {
  display: flex;
  gap: 1.5rem;
  align-items: flex-end;
  flex-wrap: wrap;
}
.cta {
  justify-self: end;
  margin-left: auto;
}
.weekly-cost {
  font-size: 2rem;
  padding: 0.8rem 1.6rem;
  background: var(--gray-bg);
}
.bottom-buttons {
  display: flex;
  justify-content: right;
}

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  overflow-x: hidden;
}
.react-datepicker-popper {
  z-index: 150 !important;
}
.table-buttons {
  border: none;
  margin-right: 0.5rem;
  cursor: pointer;
  background: transparent;
}
.flex {
  display: flex;
}
.sp-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.details-container {
  display: flex;
  gap: 20rem;
  justify-content: space-between;
  margin-top: 1.8rem;
  width: 100%;
}
.w-50 {
  width: 50%;
}
.my-half {
  margin: 0.5rem 0;
}
.grey-button {
  padding: 0.5rem 1rem;
  background: var(--accent-light-gray);
  border: none;
  border-radius: 0.4rem;
  font-weight: 500;
  margin-left: auto;
  cursor: pointer;
  margin-left: auto;
}
.elevated-white{
  display: flex;
  font-size: 2rem;
  padding: 2.2rem 1.6rem;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
  background: #fff;
  box-shadow: 0px 0px 2px #BDBDBD;
}
.dateInput, .MuiOutlinedInput-root{
  padding: 0 !important;
}

@media (max-width: 1010px){
  .page-container{
   padding: 5rem 2rem;
  }
 }